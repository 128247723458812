import { useContext, useEffect, useState } from "react"
import { Pagination } from "../../../component/Pagination"
import { BuyerItem, host, Orders, Testing } from "../../../classes"
import { GlobalContext } from "../../../context/GlobalContext"
import { Loader } from "../../../component/Loader"
import { Link, useParams } from "react-router-dom"
import close from '../../../images/closeblack.svg'
import axios from "axios"
import xlsx from "json-as-xlsx"

export const UserPage = () => {
    const auth = useContext(GlobalContext) 
    const { pk } = useParams() 
    const [load, setLoad] = useState(true)
    const [openTab, setOpenTab] = useState(1)
    const [openPopup, setOpenPopup] = useState(false)
    const [popupIndex, setPopupIndex] = useState(0)
    const [openPopupAddCourse, setOpenPopupAddCourse] = useState(false)
    const [coureNames, setCourseNames] = useState<[{pk: number, title: string}]>()
    const [targetCourse, setTargetCourse] = useState(0)
    const [getUser, setGetUser] = useState(new BuyerItem)
    const [orders, setOrders] = useState(new Orders)
    const [testing, SetTesting] = useState(new Testing)
    const [openPopupTest, setOpenPopupTest] = useState(false)
    const [openPopupIndex, setOpenPopupIndex] = useState(0)
    
    const getUserInfo = () => {
        getUser.pk = pk ? Number(pk) : 0 
        setLoad(true)
        getUser.getData(auth.user.userToken).then(function(response){
            getOrders(1)
        }).catch(function(error){
            alert("error")
        })
    }
    const getOrders = (page: number) => {
        setLoad(true)
        orders.getData(auth.user.userToken, page, "").then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        }) 
    }
    const GetOrderInfo = (itemId: number) => {
        setLoad(true)
        orders.list[itemId].getData(auth.user.userToken).then(function(response){
            setOpenPopup(true)
            setPopupIndex(itemId)
            setLoad(false)
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }    
    const getData = (page: number) => {
        setLoad(true)
        testing.getData(auth.user.userToken, page, Number(pk)).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        }) 
    }
    const loadCourseList = async () => {
        setLoad(true)

        await axios.get(`${host}course-to-group/`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then(function(res){
            setLoad(false)
            setOpenPopupAddCourse(true)
            setCourseNames(res.data)
            setTargetCourse(res.data[0].pk)
            console.log(res.data)
        }).catch(function(error){
            alert("Ошибка выполнения запроса")
            console.log(error)
        })
    }
    const handleSubmitAddCourse = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        
        setLoad(true)
         
        await axios.post(`${host}course-to-group/`, {
            "type": "user",
            "course": targetCourse,
            "group": Number(pk)
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then(function(res){
            console.log(res.data)
            alert("Курс успешно добавлен выбранной группе")
            setLoad(false)
            setOpenPopupAddCourse(false)
        }).catch(function(error){
            alert("Ошибка выполнения запроса")
            console.log(error)
        })
        
    }


    const getStatistics = async () => {
        setLoad(true)

        let data: [{
            course: string,
            module: string,
            lesson: string,
            score: string,
            test: string,
            createDate: string
        }] = [{
            course: "",
            module: "",
            lesson: "",
            score: "",
            test: "",
            createDate: ""
        }]

        await axios.post(`${host}statistics/`,{
            type: 4,
            date_start: "2020-12-12",
            date_end: "2020-12-12"
        }, {
            params: {
                'pk': pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then(function(responce){
            data = responce.data.data

            data.forEach((item) => {
                let test_text = ""
                const j_test = JSON.parse(item.test)
                j_test.forEach((item: any) => {
                    let answer_list = ""
                    item.answer.forEach((item2: any) => {
                        answer_list += `${item2}\n\r`
                    })

                    test_text += `Вопрос: ${item.question}\n\rОтвет верный: ${item.right}\n\rОтветы:${answer_list}----------\n\r`
                })
                item.test = test_text
            })

            let exl_data = [
                {
                    sheet: "Тесты",
                    columns: [
                        { label: "Дата создания", value: "createDate"},
                        { label: "Курс", value: "course" },
                        { label: "Модуль", value: "module"},
                        { label: "Урок", value: "lesson"},
                        { label: "Оценка", value: "score"},
                        { label: "Вопросы теста", value: "test"},
                    ],
                    content: data
                },  
            ]
            let settings = {
                fileName: "Statistics",
            }
            xlsx(exl_data, settings)
            setLoad(false)
        })
    }

    useEffect(()=>{ 
        getUserInfo()
    },[])

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="courses-desc">
                    <div className="courses-desc__btn">
                        <Link to={`/`}>
                            {`< Назад`}
                        </Link>
                        <button 
                            style={
                                {
                                    marginRight: 15,
                                    backgroundColor: openTab == 1 ? "green" : "rgba(242, 100, 33, 1)"
                                }
                            }
                            onClick={()=>{
                                setOpenTab(1)
                                getOrders(1)
                            }}
                        >Покупки</button>
                        <button style={{marginRight: 15}} onClick={()=>{
                            loadCourseList()
                        }}>
                            Выдать курс
                        </button>
                        <button
                            style={{
                                marginRight: 15,
                                backgroundColor: "green"
                            }}
                            onClick={()=>{getStatistics()}}
                        >
                            Статистика пользователя
                        </button>
                        <button
                            style={
                                {
                                    marginRight: 15,
                                    backgroundColor: openTab != 1 ? "green" : "rgba(242, 100, 33, 1)"
                                }
                            }
                            onClick={()=>{
                                setOpenTab(2)
                                getData(1)
                            }}
                        >Пройденные тесты</button>
                    </div>
                    <h1>Пользователь: {getUser.surname} {getUser.name} {getUser.minName}</h1>
                    <p>
                        Email: <Link to={`mailto:${getUser.email}`}>{getUser.email}</Link><br/>
                        Номер телефона: <Link to={`tell:${getUser.phone}`}>{getUser.phone}</Link>
                    </p>
                </div>

                {
                    openTab == 1 ? (
                        <>
                            <div className='courses-module lesson-test'>
                                <div className='table-list__body'>
                                    <table className="delivery">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Статус</th>
                                                <th>Пользователь</th>
                                                <th>Дата</th>
                                                <th>Сумма</th>
                                                <th>Состав заказа</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                orders.list.length ? (
                                                    orders.list.map((item, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td aria-label="ID">{item.pk}</td>
                                                                <td aria-label="Статус">{item.status}</td>
                                                                <td aria-label="Пользователь">{item.user.email}</td>
                                                                <td aria-label="Дата">
                                                                    {item.createDate.split("T")[1].split(".")[0]} /
                                                                    {item.createDate.split("T")[0]}
                                                                </td>
                                                                <td aria-label="Сумма">
                                                                    {
                                                                        item.cost != item.finalCost ? (
                                                                            <>
                                                                                <span className="desc-cost">{item.cost} руб</span><br/>
                                                                            </>
                                                                        ):""
                                                                    }
                                                                    {item.finalCost} руб
                                                                </td>
                                                                <td aria-label="Состав заказа">
                                                                    <button onClick={()=>{GetOrderInfo(index)}}>Товары</button>
                                                                </td> 
                                                            </tr> 
                                                        )
                                                    })
                                                ):""
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="table-list__pager">
                                <Pagination 
                                    page={orders.page}
                                    pageCount={orders.pageCount}
                                    setLoadReview={setLoad} 
                                    SwitchPage={getOrders}                         
                                />          
                            </div>
                        </>
                    ):(
                        <>
                            <div className="courses-module lesson-test">
                                <div className='table-list__body'>
                                    <table className="delivery">
                                        <thead>
                                            <tr>
                                                <th>ID</th> 
                                                <th>Урок</th>
                                                <th>Оценка</th>
                                                <th style={{width: 200}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                testing.list.length ? (
                                                    testing.list.map((item, index) => {
                                                        return(
                                                            <tr key={index}>
                                                                <td aria-label="ID">{item.pk}</td>
                                                                <td aria-label="Урок">{item.lesson.title}</td>
                                                                <td aria-label="Оценка">{item.score}</td>
                                                                <td aria-label="">
                                                                    <button onClick={()=>{
                                                                        setOpenPopupIndex(index)
                                                                        setOpenPopupTest(true)
                                                                    }}>Подробнее</button>
                                                                </td>
                                                            </tr> 
                                                        )
                                                    })
                                                ):""
                                            }
                                        </tbody>
                                    </table>
                                </div> 
                            </div>
                            <div className="table-list__pager">    
                                <Pagination 
                                    page={testing.page}
                                    pageCount={testing.pageCount}
                                    setLoadReview={setLoad} 
                                    SwitchPage={getData}                         
                                />      
                            </div>
                        </>   
                    )
                }
            </div>

            {
                openPopupTest ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupTest(false)}} />
                            </div>
                            <div className="adduser-form test-list">  
                                {
                                    testing.list[openPopupIndex].test.length ? (
                                        testing.list[openPopupIndex].test.map((item: any, index) => {
                                            return(
                                                <div className="test-list__item" key={index}>
                                                    <div className="test-list__name">
                                                        <b>Вопрос:</b> {item.question} <span>({item.right ? "Верно":"Ошибка"})</span>
                                                    </div>
                                                    <div className="test-list__answer">
                                                        {
                                                            item.questionType == 1 ? (
                                                                item.answer
                                                            ):(
                                                                item.answer.map((item2: any, index: number) => {
                                                                    return(
                                                                        <div>{item2}</div>
                                                                    )
                                                                })
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openPopupAddCourse ? (
                    <div className="popup-form mini">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupAddCourse(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitAddCourse}>
                                    <label>
                                        Укажите курс для добавления
                                        <select onChange={(e) => {
                                            setTargetCourse(Number(e.target.value))
                                        }} >
                                            {
                                                coureNames ? (
                                                    coureNames.map((item: {pk: number, title: string}, index) => (
                                                        <option selected={targetCourse == Number(item.pk)} key={index} value={item.pk}>{item.title}</option>
                                                    ))
                                                ):""
                                            }
                                        </select>
                                    </label>
                                    <button>Добавить</button>
                                </form>      
                            </div>
                        </div>
                    </div>
                ) : ""
            }

            {
                openPopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                {
                                    orders.list[popupIndex].order_item.length ? (
                                        orders.list[popupIndex].order_item.map((item, index) => {
                                            return(
                                                <div key={index} className="popup-order__item row">
                                                    <div className="col-6">
                                                        <div className="grid">
                                                            <Link to={`/module/${item.module.pk}/`}>
                                                                {item.module.title}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 right">
                                                        {
                                                            item.cost != item.finalCost ? (
                                                                <>
                                                                    <span className="desc-cost">{item.cost} руб</span><br/>
                                                                </>
                                                            ):""
                                                        }
                                                        {item.finalCost} руб
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}