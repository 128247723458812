import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import { GlobalContext } from "../../../../../context/GlobalContext"
import { Link, useParams } from "react-router-dom"
import { Loader } from "../../../../../component/Loader"
import { LessonsItem, Testing } from "../../../../../classes"
import { Pagination } from "../../../../../component/Pagination"
import close from '../../../../../images/closeblack.svg'
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Question } from "../../../../../component/Question"

export const LessonPage = () => {
    const auth = useContext(GlobalContext) 
    const { pk, module, lesson } = useParams() 

    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupTest, setOpenPopupTest] = useState(false)
    const [openPopupIndex, setOpenPopupIndex] = useState(0)

    const [lessonsItem, setLessonsItem] = useState(new LessonsItem)
    const [testing, setTesting] = useState(new Testing)

    const [test, setTest] = useState([{}])
    const [load, setLoad] = useState(true)

    const lessonRef = useRef<TinyMCEEditor>();
    const [formLesson, setFormLesson] = useState({
        sort: 0,
        title: "",
        miniDesc: "",
        content: "",
        test: [{}]
    })

    const handleSubmitLesson = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault() 
        setLoad(true)
        const lesson = {
            ...formLesson,
            content: lessonRef.current!.getContent(),
            test: test
        }

        lessonsItem.UpdateData(auth.user.userToken, lesson).then(function(response){
            alert(response)
            setLoad(false)
            setOpenPopup(false)
            getLesson()
        }).catch(function(error){
            alert("error")
        })
        console.log(lesson)
    }
    const changeHandlerLesson = (event: ChangeEvent<HTMLInputElement>) => {
        setFormLesson({...formLesson, [event.target.name]: event.target.value})
    }




    const getLesson = () => {
        setLoad(true)
        lessonsItem.pk = Number(lesson)
        lessonsItem.getData(auth.user.userToken).then(function(response){
            setFormLesson({
                sort: lessonsItem.sort,
                title: lessonsItem.title,
                miniDesc: lessonsItem.miniDesc,
                content: lessonsItem.content,
                test: lessonsItem.test
            }) 
            setTest(lessonsItem.test)
            setLoad(false) 
        }).catch(function(error){
            alert("error")
        })
    }
    const getData = (page: number) => {
        setLoad(true)
        testing.getData(auth.user.userToken, page).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        }) 
    }

    useEffect(()=>{
        getLesson()
        getData(1)
    },[])

    if(load){
        return(
            <Loader />
        )
    }

    return (
        <>
            <div className="content">
                <div className="courses-desc">
                    <div className="courses-desc__btn">
                        <Link to={`/courses/${pk}/${module}/`}>
                            {`< Назад`}
                        </Link>
                        <button onClick={()=>{setOpenPopup(true)}} style={{marginRight: 10}}>Изменить урок</button> 
                    </div>
                    <h1>Урок: {lessonsItem.title}</h1>
                    <p>{lessonsItem.miniDesc}</p>
                </div>
                <div className="courses-module lesson-test">
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th> 
                                    <th>Пользователь</th>
                                    <th>Оценка</th>
                                    <th style={{width: 200}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    testing.list.length ? (
                                        testing.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">
                                                        {item.pk}
                                                    </td>
                                                    <td aria-label="Пользователь">
                                                        <Link to={`/user/${item.user.pk}/`}>{item.user.email}</Link>
                                                    </td>
                                                    <td aria-label="Оценка">{item.score}</td>
                                                    <td aria-label="">
                                                        <button onClick={()=>{
                                                            console.log(testing.list)
                                                            setOpenPopupIndex(index)
                                                            setOpenPopupTest(true)
                                                        }}>Подробнее</button>
                                                    </td>
                                                </tr> 
                                            )
                                        })
                                    ):""
                                }
                            </tbody>
                        </table>
                    </div> 
                </div>
                <div className="table-list__pager">    
                    <Pagination 
                        page={testing.page}
                        pageCount={testing.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={getData}                         
                    />      
                </div>
            </div> 

            {
                openPopupTest ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupTest(false)}} />
                            </div>
                            <div className="adduser-form test-list">  
                                {
                                    testing.list[openPopupIndex].test.length ? (
                                        testing.list[openPopupIndex].test.map((item: any, index) => {
                                            return(
                                                <div className="test-list__item" key={index}>
                                                    <div className="test-list__name">
                                                        <b>Вопрос:</b> 
                                                        <div dangerouslySetInnerHTML={{ __html: item.question }} />
                                                        <span>
                                                            
                                                            <b>({item.right})</b>
                                                        </span>
                                                    </div>
                                                    <div className="test-list__answer">
                                                        {
                                                            item.questionType == 1 ? (
                                                                item.answer
                                                            ):(
                                                                item.answer.map((item2: any, index: number) => {
                                                                    return(
                                                                        <div>{item2}</div>
                                                                    )
                                                                })
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openPopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitLesson}>
                                    <label>
                                        Сортировка
                                        <input type="number" min={0} required name="sort" value={formLesson.sort} onChange={changeHandlerLesson} />
                                    </label>
                                    <label>
                                        Название урока
                                        <input type="text" maxLength={255} required name="title" value={formLesson.title} onChange={changeHandlerLesson} />
                                    </label>
                                    <label>
                                        Описание урока
                                        <textarea maxLength={510} required name="miniDesc" value={formLesson.miniDesc} onChange={(event: ChangeEvent<HTMLTextAreaElement>)=>{    
                                            setFormLesson({...formLesson, [event.target.name]: event.target.value})
                                        }} />
                                    </label>
                                    <label>
                                        Содержимое урока
                                        <Editor
                                            onInit={(evt, editor) => lessonRef.current = editor}
                                            apiKey='te0f6xps8s75ppw6vsh5netbpg8ber5hvml5ad5balibyuj7' 
                                            initialValue={formLesson.content}
                                            init={{
                                                height: 300,
                                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'],
                                                toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </label>

                                    <div>
                                        <b className="label-title">Тестирование</b>
                                            {
                                                test.length ? (
                                                    test.map((item, index) => {
                                                        return(
                                                            <Question key={index} index={index} test={test} setTest={setTest}  />
                                                        )
                                                    })
                                                ):""
                                            } 
                                    </div>
                                    <span 
                                        style={{
                                            fontSize: 14,
                                            marginTop: "-25px",
                                            marginBottom: "15px",
                                            display: "block",
                                            textAlign: "right",
                                            textDecoration: "underline",
                                            cursor: "pointer"
                                        }}
                                        onClick={()=>{
                                        let array = test.map((item: any, index: number) => {
                                            return {...item}
                                        })
                                        array.push(
                                            {
                                                "questionText": "<p>Описание</p>",
                                                "answer": [
                                                    {
                                                        "answer": true,
                                                        "text": ""
                                                    }
                                                ]
                                            }
                                        )
                                        setTest(array)
                                    }}>Добавить вопрос</span>
                                    <button>Сохранить</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}