import { ChangeEvent, useEffect, useRef, useState } from "react"
import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import close from '../../images/closeblack.svg'

export const Question = (props: any) => {
    const aboutRef = useRef<TinyMCEEditor>()
    const [userType, setUserType] = useState(typeof props.test[props.index].answer[0].answer === "boolean" ? "1" : "2")
    const [question, setQuestion] = useState(props.test[props.index].questionText)
    const [ansType1, setAnsType1] = useState(typeof props.test[props.index].answer[0].answer !== "boolean" ? [{answer: true, text: ""}] : props.test[props.index].answer)
    const [ansType2, setAnsType2] = useState(typeof props.test[props.index].answer[0].answer === "boolean" ? [{question: "", answer: ""}] : props.test[props.index].answer)

    useEffect(()=>{
        const node = {
            "questionText": question,
            "answer": userType == "1" ? ansType1 : ansType2
        }
        let array = props.test.map((item: any, index: number) => {
            return {...item}
        })
        array[props.index] = node
        props.setTest(array)
    },[ansType1, ansType2, userType, question])

    return(
        <div className="test-wrapper">
            <div className="question-item__wrapper">
                <label>
                    Тип вопроса
                    <select 
                        value={userType} 
                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                            setUserType(event.target.value)
                        }}
                    >
                        <option value={"1"}>Один или несколько ответов</option>
                        <option value={"2"}>Сопоставление</option>
                    </select>
                </label>

                <div>
                    <label>
                        Текст вопроса
                        <Editor
                            onInit={(evt, editor) => aboutRef.current = editor}
                            apiKey='te0f6xps8s75ppw6vsh5netbpg8ber5hvml5ad5balibyuj7' 
                            initialValue={props.test[props.index].questionText}
                            init={{
                                menubar: false,
                                height: 200,
                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'],
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                            onChange={(e)=>{
                                setQuestion(aboutRef.current!.getContent())
                            }}
                        />
                    </label>

                    {
                        userType == "1" ? (
                            <div className="answ-list">
                                <h2>Варианты ответа</h2>
                                
                                {
                                    ansType1.length ? (
                                        ansType1.map((item: any, index: number) => {
                                            return(
                                                <div className="row">
                                                    <div className="answ-list__chk">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.answer} 
                                                            onChange={(e)=>{
                                                                let array = ansType1.map((item: any) => {
                                                                    return {...item}
                                                                })
                                                                array[index].answer = !array[index].answer
                                                                setAnsType1(array)
                                                            }}
                                                        />
                                                    </div>
                
                                                    <div className="answ-list__input">
                                                        <label>
                                                            <input type="text" placeholder="Вариант ответа" value={item.text} onChange={(e)=>{
                                                                let array = ansType1.map((item: any, index2: number) => {
                                                                    return {...item, text: index == index2 ? e.target.value : item.text}
                                                                })
                                                                setAnsType1(array)
                                                            }} />
                                                        </label>
                                                    </div>
                
                                                    <div className="answ-list__del">
                                                        {
                                                            index > 0 ? (
                                                                <img 
                                                                    src={close} 
                                                                    onClick={()=>{

                                                                    let array = ansType1.map((item: any, index: number) => {
                                                                        return {...item, answer: false}
                                                                    })
                                                                    array[0].answer = true
                                                                    array.splice(index, 1)
                                                                    setAnsType1(array)
                                                                    }}
                                                                />
                                                            ):""
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                                
                                <p style={{paddingTop: 10}}>
                                    <span
                                        onClick={()=>{
                                            let array = ansType1.map((item: any, index: number) => {
                                                return item
                                            })
                                            array.push({
                                                answer: false,
                                                text: ""
                                            })
                                            setAnsType1(array)
                                        }}
                                    >Добавить вариант ответа</span>
                                </p>

                            </div>
                        ):(

                            <div className="answ-list">
                                <h2>Варианты ответа</h2>

                                {
                                    ansType2.length ? (
                                        ansType2.map((item: any, index: number) => {
                                            return(
                                                <div className="row">
                                                    <div className="type2-qw">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <input 
                                                                    type="text" 
                                                                    required
                                                                    placeholder="Ключ" 
                                                                    value={item.question} 
                                                                    onChange={(e)=>{
                                                                        let array = ansType2.map((item: any, index2: number) => {
                                                                            return {...item, question: index == index2 ? e.target.value : item.question}
                                                                        })
                                                                        setAnsType2(array)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input 
                                                                    type="text" 
                                                                    required
                                                                    placeholder="Значение" 
                                                                    value={item.answer} 
                                                                    onChange={(e)=>{
                                                                        let array = ansType2.map((item: any, index2: number) => {
                                                                            return {...item, answer: index == index2 ? e.target.value : item.answer}
                                                                        })
                                                                        setAnsType2(array)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="type2-del">
                                                        {
                                                            index > 0 ? (
                                                                <img 
                                                                    src={close} 
                                                                    onClick={()=>{
                                                                        let array = ansType2
                                                                        array.splice(index, 1)
                                                                        setAnsType2(array)
                                                                    }}
                                                                />
                                                            ):""
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                                <p style={{paddingTop: 10}}>
                                    <span
                                        onClick={()=>{
                                            let array = ansType2.map((item: any, index: number) => {
                                                return item
                                            })
                                            array.push({
                                                question: "",
                                                answer: ""
                                            })
                                            setAnsType2(array)
                                        }}
                                    >Добавить вариант ответа</span>
                                </p>
                            </div>
                        )
                    }

                </div>
                <p>
                    <span onClick={()=>{
                        let array = props.test.map((item: any, index: number) => {
                            return {...item}
                        })
                        //if(array.length)
                        array.splice(props.index, 1)
                        console.log(array)
                        props.setTest(array)
                    }}>Удалить</span>
                </p>
            </div>
        </div>
    )
}