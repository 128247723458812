import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Loader } from "../../component/Loader"
import { Link } from "react-router-dom"
import { Pagination } from "../../component/Pagination"
import { Slider } from "../../classes"
import close from "../../images/close.svg"

export const SliderPage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const [openPopup, setOpenPopup] = useState(false)
    const [slider, setSlider] = useState(new Slider())
    const [createForm, setCreateForm] = useState({
        title: "",
        desc: "",
        link: "",
        image_link: "",
        sort: 0
    })

    const GetData = (page: number = 1) => {
        setLoad(true)
        slider.getData(auth.user.userToken, page).then(function(responce){
            setLoad(false)
        }).catch(function(error){
            alert("error")
            setLoad(false)
        })
    }

    const changeHandlerCreate = (event: ChangeEvent<HTMLInputElement>) => {
        setCreateForm({ ...createForm, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)

        slider.createData(auth.user.userToken, createForm).then(function(responce){
            setOpenPopup(false)
            GetData()
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        GetData()
    }, [])

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-6">
                            
                        </div>
                        <div className="col-md-6 right">
                            <button onClick={()=>{
                                setOpenPopup(true)
                            }}>Добавить слайд</button>
                        </div>
                    </div>
                </div>

                <div className='table-list'>
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>Сортировка</th>
                                    <th>Название</th>
                                    <th>Описание</th>
                                    <th style={{
                                        width: 200,
                                        maxWidth: '100%'
                                    }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    slider.list.map((item, index: number) => (
                                        <tr key={0}>
                                            <td aria-label="Сортировка">{item.sort}</td>
                                            <td aria-label="Название">{item.title}</td>
                                            <td aria-label="Описание">{item.desc}</td>
                                            <td>
                                                <button 
                                                    style={{backgroundColor: "red"}}
                                                    onClick={()=>{
                                                        setLoad(true)
                                                        item.Delete_item(auth.user.userToken).then(function(responce){
                                                            GetData()
                                                        }).catch(function(error){
                                                            alert("error")
                                                        })
                                                    }}
                                                >
                                                    Удалить
                                                </button>
                                            </td>
                                        </tr> 
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-list__pager">    
                    <Pagination 
                        page={slider.page}
                        pageCount={slider.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />      
                </div>
            </div>

            {
                openPopup ? (
                    <div className="popup-form mini">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        Сортировка
                                        <input required name="sort" value={createForm.sort} onChange={changeHandlerCreate} />
                                    </label>
                                    <label>
                                        Заголовок
                                        <input maxLength={255} required name="title" value={createForm.title} onChange={changeHandlerCreate} />
                                    </label>
                                    <label>
                                        Описание
                                        <input maxLength={510} required name="desc" value={createForm.desc} onChange={changeHandlerCreate} />
                                    </label>
                                    <label>
                                        Ссылка
                                        <input required name="link" value={createForm.link} onChange={changeHandlerCreate} />
                                    </label>
                                    <label>
                                        Ссылка на фото
                                        <input required name="image_link" value={createForm.image_link} onChange={changeHandlerCreate} />
                                    </label>
                                    <button>Создать</button>
                                </form>      
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}