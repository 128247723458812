import axios from "axios"
import xlsx from "json-as-xlsx"
import { useContext, useState } from "react"
import { read, utils } from 'xlsx'
import { GlobalContext } from "../../context/GlobalContext"
import { host } from "../../classes"
import { Loader } from "../../component/Loader"

export const StatisticsPage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(false)
    const [formOverview, setFormOverview] = useState({
        date_start: Date(),
        date_end: Date()
    })

    const getOverviewStats = async () => {
        let data: {
            id: string,
            fio: string,
            email: string,
            date: string
        }[] = []

        await axios.post(`${host}statistics/`,{
            type: 1,
            date_start: formOverview.date_start,
            date_end: formOverview.date_end
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then(function(responce){
            responce.data.data.forEach((item: any) => {
                data.push({
                    id: item.pk,
                    fio: `${item.surname} ${item.name} ${item.minName}`,
                    email: item.email,
                    date: item.createDate
                })
            })
        })
        return data
    }
    const OverviewStats = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let content: {
            id: string,
            fio: string,
            email: string,
            date: string
        }[] = []
        content.shift()
        
        setLoad(true)
        getOverviewStats().then(function(responce){
            setLoad(false)
            content = responce
            console.log(responce)
            let data = [
                {
                    sheet: "Statistics",
                    columns: [
                        { label: "ID", value: "id" },
                        { label: "ФИО", value: "fio" },
                        { label: "Email", value: "email" },
                        { label: "Дата регистрации", value: "date" },
                    ],
                    content: content!
                }, 
            ]
            let settings = {
                fileName: "Statistics",
            }
            xlsx(data, settings)
        }).catch(function(error){
            alert("error")
        })

    
    }


    const getCourseStats = async () => {
        let data: {
            active_course_count: number,
            course_count: number,
            top_order_course: {
                id: number,
                title: string,
                count: number
            }[],
            certificates: {
                id: number,
                title: string,
                count: number
            }[],
            percent_complete: number
        } = {
            active_course_count: 0,
            course_count: 0,
            top_order_course: [],
            certificates: [],
            percent_complete: 0
        }

        await axios.post(`${host}statistics/`,{
            type: 2,
            date_start: "2020-12-12",
            date_end: "2020-12-12"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then(function(responce){
            data = responce.data.data
        })
        
        return data
    }
    const CourseStats = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoad(true)
        getCourseStats().then(function(responce){ 
            setLoad(false)
            let content : {
                active_course_count: string|number,
                course_count: string|number,
                percent_complete: string|number,
                top_order_course: string|number,
                certificates: string|number,
            }[] = [{
                active_course_count: responce.active_course_count,
                course_count: responce.course_count,
                percent_complete: `${responce.percent_complete}%`,
                top_order_course: "",
                certificates: "",
            }]
 
            let data = [
                {
                    sheet: "Statistics",
                    columns: [
                        { label: "Кол-во активных курсов", value: "active_course_count" },
                        { label: "Кол-во курсов всего", value: "course_count" },
                        { label: "Процент завершенных модулей", value: "percent_complete" },
                    ],
                    content: content
                }, 
                {
                    sheet: "Самые популярные курсы",
                    columns: [
                        { label: "ID", value: "id" }, 
                        { label: "Название", value: "title" }, 
                        { label: "Кол-во покупок модулей", value: "count" }, 
                    ],
                    content: responce.top_order_course
                },
                {
                    sheet: "Топ курсов по прохождению",
                    columns: [
                        { label: "ID", value: "id" }, 
                        { label: "Название", value: "title" }, 
                        { label: "Кол-во полных прохождений", value: "count" }, 
                    ],
                    content: responce.certificates
                }
            ]
            let settings = {
                fileName: "Statistics",
            }
            xlsx(data, settings)
        }).catch(function(error){
            alert("error")
        })
    }


    if(load)
        return(
            <Loader />
        )


    return(
        <>
            <div className="statistics-wrapper">
                <div className="statistics-body" style={{marginBottom: 20}}>
                    <h3 style={{marginTop: 5, marginBottom: 20}}>Обзорная статистика</h3>
                    <form onSubmit={OverviewStats}>
                        <div className="row"> 
                            <div className="col-md-4">
                                <label>
                                    Дата от:
                                    <input type="date" required value={formOverview.date_start} onChange={(e: any) => {setFormOverview({...formOverview, date_start: e.target.value})}} />
                                </label>
                            </div> 
                            <div className="col-md-4">
                                <label>
                                    Дата до:
                                    <input type="date" required value={formOverview.date_end} onChange={(e: any) => {setFormOverview({...formOverview, date_end: e.target.value})}} />
                                </label>
                            </div> 
                        </div>
                        <button>Загрузить</button>
                    </form>
                </div>
                
                <div className="statistics-body" style={{marginBottom: 20}}>
                    <h3 style={{marginTop: 5, marginBottom: 20}}>Статистика по курсам</h3>
                    <form onSubmit={CourseStats}> 
                        <button>Загрузить</button>
                    </form>
                </div>
            </div>
        </>
    )
}