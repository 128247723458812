import { Route } from "react-router-dom"
import { LoginPage } from "./pages/LoginPage"
import { useContext } from "react"
import { GlobalContext } from "./context/GlobalContext"
import { UserListPage } from "./pages/UserListPage"
import { GroupPage } from "./pages/GroupPage"
import { GroupItemPage } from "./pages/GroupPage/GroupItemPage"
import { OrdersPage } from "./pages/OrdersPage"
import { MediaPage } from "./pages/MediaPage"
import { SettingPage } from "./pages/SettingPage"
import { MailsPage } from "./pages/MailsPage"
import { CoursesPage } from "./pages/CoursesPage"
import { TeachersPage } from "./pages/TeachersPage"
import { CoursesItemPage } from "./pages/CoursesPage/CoursesItemPage"
import { ModuleItemPage } from "./pages/CoursesPage/CoursesItemPage/ModuleItemPage"
import { ReviewsPage } from "./pages/ReviewsPage"
import { LessonPage } from "./pages/CoursesPage/CoursesItemPage/ModuleItemPage/LessonPage"
import { UserPage } from "./pages/UserListPage/UserPage"
import { StatisticsPage } from "./pages/StatisticsPage"
import { SliderPage } from "./pages/SliderPage"

export const useMyRoutes = () => { 
    const auth = useContext(GlobalContext)
    
    return (
        <>
            {
                auth.user.userToken != "" ? (
                    <>
                        <Route path={`/`} element={<UserListPage/>} />
                            <Route path={`/user/:pk/`} element={<UserPage/>} />
                        <Route path={`/group/`} element={<GroupPage/>} />
                            <Route path={`/group/:pk/`} element={<GroupItemPage/>} />
                        <Route path={`/orders/`} element={<OrdersPage/>} />
                        <Route path={`/media/`} element={<MediaPage/>} />
                        <Route path={`/setting/`} element={<SettingPage/>} />
                        <Route path={`/mails/`} element={<MailsPage/>} />
                        <Route path={`/courses/`} element={<CoursesPage/>} />
                            <Route path={`/courses/:pk/`} element={<CoursesItemPage/>} />
                            <Route path={`/courses/:pk/:module/`} element={<ModuleItemPage/>} />
                            <Route path={`/courses/:pk/:module/:lesson/`} element={<LessonPage/>} />
                        <Route path={`/teachers/`} element={<TeachersPage/>} />
                        <Route path={`/reviews/`} element={<ReviewsPage/>} />
                        <Route path={`/statistics/`} element={<StatisticsPage/>} />
                        <Route path={`/slider/`} element={<SliderPage/>} />
                        
                    </>
                ):(
                    <Route path={`/`} element={<LoginPage/>} />
                )
            }
        </>
    )
}