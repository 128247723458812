import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { GlobalContext } from "../../../../context/GlobalContext"
import { ModuleItem } from "../../../../classes"
import { Loader } from "../../../../component/Loader"
import close from '../../../../images/closeblack.svg'

import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Question } from "../../../../component/Question"

export const ModuleItemPage = () => {
    const auth = useContext(GlobalContext) 
    const { pk, module } = useParams() 
    const [openPopupSetting, setOpenPopupSetting] = useState(false)
    const [openPopupModule, setOpenPopupModule] = useState(false)
    const [moduleItem, SetModuleItem] = useState(new ModuleItem)
    const [load, setLoad] = useState(true)

    const [test, setTest] = useState(
        [
            {
                "questionText": "<p>Описание</p>",
                "answer": [
                    {
                        "answer": true,
                        "text": ""
                    }
                ]
            }
        ]
    )

    const lessonRef = useRef<TinyMCEEditor>();

    const [formModule, setFormModule] = useState({
        title: "",
        miniDesc: "",
        cost: 0,
        finalCost: 0,
        sort: 0
    })

    const [formLesson, setFormLesson] = useState({
        sort: 0,
        title: "",
        miniDesc: "",
        content: "",
        test: [{}]
    })

    const GetData = () => {
        setLoad(true)
        moduleItem.getData(auth.user.userToken).then(function(responce){
            setLoad(false)
            console.log(moduleItem)
            setFormModule({
                title: moduleItem.title,
                miniDesc: moduleItem.miniDesc,
                cost: moduleItem.cost,
                finalCost: moduleItem.finalCost,
                sort: moduleItem.sort
            })
        }).catch(function(error){
            alert("error")
        })
    }

    const handleSubmitLesson = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault() 
        setLoad(true)
        const lesson = {
            ...formLesson,
            content: lessonRef.current!.getContent(),
            test: test
        }

        moduleItem.SetLesson(auth.user.userToken, lesson).then(function(response){
            alert(response)
            setOpenPopupModule(false)
            GetData()
        }).catch(function(error){
            alert("error")
        })
    }

    const handleSubmitModule = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault() 
        setLoad(true)
        moduleItem.UpdateData(auth.user.userToken, formModule).then(function(response){
            alert(response)
            setOpenPopupSetting(false)
            GetData()
        }).catch(function(error){
            alert("error")
        })
        setLoad(true)
    }

    const changeHandlerModule = (event: ChangeEvent<HTMLInputElement>) => {
        setFormModule({...formModule, [event.target.name]: event.target.value})
    }

    const changeHandlerLesson = (event: ChangeEvent<HTMLInputElement>) => {
        setFormLesson({...formLesson, [event.target.name]: event.target.value})
    }
    
    useEffect(()=>{
        if(pk)
            moduleItem.pk = Number(module)
        GetData()
    },[])
    
    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="courses-desc">
                    <div className="courses-desc__btn">
                        <Link to={`/courses/${pk}/`}>
                            {`< Назад`}
                        </Link>
                        <button onClick={()=>{setOpenPopupSetting(true)}} style={{marginRight: 10}}>Изменить модуль</button>
                        <button onClick={()=>{setOpenPopupModule(true)}} style={{backgroundColor: "green"}}>Создать урок</button>
                    </div>
                    <h1>Модуль: {moduleItem.title}</h1>
                    <p>{moduleItem.miniDesc}</p>
                </div>
                <div className="courses-module">

                <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Сортировка</th>
                                    <th>Название модуля</th>
                                    <th>Описание</th>
                                    <th>Активность</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    moduleItem.list.map((item, index) => {
                                        return(
                                            <tr key={index}>
                                                <td aria-label="ID">
                                                    <Link to={`/courses/${pk}/${module}/${item.pk}/`}>
                                                        {item.pk}
                                                    </Link>
                                                </td>
                                                <td aria-label="Сортировка">{item.sort}</td>
                                                <td aria-label="Название урока">{item.title}</td>
                                                <td aria-label="Описание">{item.miniDesc}</td>
                                                <td aria-label="Активность">
                                                    <button onClick={()=>{
                                                            item.UpdateActive(auth.user.userToken).then(function(response){
                                                                alert(response)
                                                                GetData()
                                                            }).catch(function(error){
                                                                alert("error")
                                                            })
                                                        }} style={{backgroundColor: !item.active ? "red":"green"}}>
                                                            {item.active ? "Да":"Нет"}
                                                        </button>
                                                </td>
                                            </tr> 
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div> 
                </div>
            </div> 

            {
                openPopupSetting ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupSetting(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitModule}>
                                    <label>
                                        Сортировка
                                        <input type="number" min={0} required name="sort" value={formModule.sort} onChange={changeHandlerModule} />
                                    </label>
                                    <label>
                                        Название модуля
                                        <input type="text" maxLength={255} required name="title" value={formModule.title} onChange={changeHandlerModule} />
                                    </label>
                                    <label>
                                        Описание модуля
                                        <textarea maxLength={510} required name="miniDesc" value={formModule.miniDesc} onChange={(event: ChangeEvent<HTMLTextAreaElement>)=>{    
                                            setFormModule({...formModule, [event.target.name]: event.target.value})
                                        }} />
                                    </label>
                                    <label>
                                        Цена модуля
                                        <input type="number" min={0} required name="cost" value={formModule.cost} onChange={changeHandlerModule} />
                                    </label> 
                                    <button>Обновить</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openPopupModule ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupModule(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitLesson}>
                                    <label>
                                        Сортировка
                                        <input type="number" min={0} required name="sort" value={formLesson.sort} onChange={changeHandlerLesson} />
                                    </label>
                                    <label>
                                        Название урока
                                        <input type="text" maxLength={255} required name="title" value={formLesson.title} onChange={changeHandlerLesson} />
                                    </label>
                                    <label>
                                        Описание урока
                                        <textarea maxLength={510} required name="miniDesc" value={formLesson.miniDesc} onChange={(event: ChangeEvent<HTMLTextAreaElement>)=>{    
                                            setFormLesson({...formLesson, [event.target.name]: event.target.value})
                                        }} />
                                    </label>
                                    <label>
                                        Содержимое урока
                                        <Editor
                                            onInit={(evt, editor) => lessonRef.current = editor}
                                            apiKey='te0f6xps8s75ppw6vsh5netbpg8ber5hvml5ad5balibyuj7' 
                                            initialValue="<p>Описание</p>"
                                            init={{
                                                height: 300,
                                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'],
                                                toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </label>

                                    <div>
                                        <b className="label-title">Тестирование</b>
                                        {
                                            test.length ? (
                                                test.map((item, index) => {
                                                    return(
                                                        <Question key={index} index={index} test={test} setTest={setTest}  />
                                                    )
                                                })
                                            ):""
                                        } 
                                    </div>
                                    <span 
                                        style={{
                                            fontSize: 14,
                                            marginTop: "-25px",
                                            marginBottom: "15px",
                                            display: "block",
                                            textAlign: "right",
                                            textDecoration: "underline",
                                            cursor: "pointer"
                                        }}
                                        onClick={()=>{
                                        let array = test.map((item: any, index: number) => {
                                            return {...item}
                                        })
                                        array.push(
                                            {
                                                "questionText": "<p>Описание</p>",
                                                "answer": [
                                                    {
                                                        "answer": true,
                                                        "text": ""
                                                    }
                                                ]
                                            }
                                        )
                                        setTest(array)
                                    }}>Добавить вопрос</span>

                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}